/*======================
    404 page
=======================*/

#page-container {
  display:flex;
  flex-direction:column;

  /* fallback height */
  min-height:100vh;

  /* new small viewport height for modern browsers */
  min-height:100svh;
}

#page-container > footer {
  margin-top:auto;
}

.margin-auto {
  margin: auto
}

a{
  text-decoration: none !important;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  color: #ff7e67;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  padding: 8px 37px !important;
  font-size: 16px;
  font-weight: 400;
  background-color: #ff7e67;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  margin-right: 15px;
}

.content_box_404 {
  margin-top: -50px;
}

.success_background {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2332606/media/32272523dd03734a876ea8312929498c.gif);
  height: 400px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.success_background h1 {
  font-size: 40px;
  color: #ff7e67;
}

/* .modal-content{
  width: 1000px !important;
} */

.s_modal{
  max-width: 1000px;
}
.text-black{
  cursor: pointer !important;
}

.navbar-expand-xl .navbar-nav .dropdown-menu {
  position: absolute;
  right: 0px;
}

button, input, optgroup, select, textarea {
  /* margin: 10px; */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.resendOtpbtn{
  outline: none;
    border: none;
    padding: 5px 10px;
    border-radius: 20px;
    color: red;
    transition: .5s ease-in-out;
}
.resendOtpbtn:hover{
  color: #fff;
  background-color: black;
}
input::placeholder{
  color: #282727a8 !important; 
}
.mainSearchBox{
  display: flex;
  justify-content: space-evenly;
}
.searchBoxinput{
  margin: 0px 20px;
}
.noBookingFound{
  text-align: center;
}

.modal.fade.show{
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.t_text{
  border: none;
  background-color: transparent;
  color: #ff7e67;
  font-size: 14px;
  font-weight: 500;
}

.v_code input{
  min-width: 30px;
}

.dropdown-menu-end {
	left: auto;
	right: 0;
	top: 45px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
/* button { */
  /* background: #5469d4; */
  /* font-family: Arial, sans-serif; */
  /* color: #ffffff; */
  /* font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease; */
  /* box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07); */
/* } */

/* button:hover {
  filter: contrast(115%);
} */

/* button:disabled {
  opacity: 0.5;
  cursor: default;
} */
.text-button {
  --cui-btn-active-border-color: transparent;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.fc .fc-button .fc-icon {
  font-size: 1.5em;
  vertical-align: middle;
  color: black;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}




@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
    margin: auto;
  }

  .checkoutForm {
    width: auto;
    min-width: initial;
    margin: auto;
  }
}


.addColorTopopover{
  color : red !important;
  
}

.popover-body{
  border-radius:10px;
  color:red !important;
text-align:center;
border:none !important;
border-bottom:1px solid rgba(0,0,0,0.175) !important;
}

.pr_modal {
  max-width: 570px;
  width: 100%;
}

.proUImg{
  position:relative;
  max-width:197px;
  width:100%;
  height:197px;
  margin:auto;
}

.proUImg i {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #ff745c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 10;
}

.proUImg img {
  width: 200px;
  height: 200px;
}


.fc-button{
  color:black !important;
}
.fc-button-active{
  /* background: #ff9e8d !important; */
  color: #ff9e8d !important;
}


/* .chr{
  cursor: pointer;
}

.chr:hover{
  opacity: 0.8;
} */